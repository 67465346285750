import "./OcyanWeb.scss";
import logo from "../../../assets/logo.png";
import icon1 from "../../../assets/react-logo.png";
import icon2 from "../../../assets/js-logo.png";
import icon3 from "../../../assets/html-logo.png";
import icon4 from "../../../assets/css-logo.png";
import photo1 from "../../../assets/ocyanWeb/ocyan-web-1.jpg";
import photo2 from "../../../assets/ocyanWeb/ocyan-web-2.jpg";
import photo3 from "../../../assets/ocyanWeb/ocyan-web-3.gif";
import photo4 from "../../../assets/ocyanWeb/ocyan-web-4.webp";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import React from "react";

const OcyanWeb = () => {
  return (
    <section className="ocyanWeb">
      <div className="ocyanWeb__container">
        <div className="ocyanWeb__logo">
          <Link className="ocyanWeb__logo-link" to={"/"}>
            <img
              className="ocyanWeb__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <h1 className="ocyanWeb-heading">OCYAN</h1>
        <h2 className="ocyanWeb-subheading">Web Design & Development</h2>

        <section className="ocyanWeb-container">
          <div className="ocyanWeb__summary">
            <div className="ocyanWeb__summary-intro">
              <p className="ocyanWeb__summary-text">
                Ocyan is a software consultancy with a focus on cutting edge
                tech. Up until now they’d been operating solely through word of
                mouth but with their business accelerating in growth and
                following a recent rebrand, they needed a website to both
                attract and communicate with potential clients.<br></br>
                <br></br>They approached me to design & build a concise, single
                page brochure site with the intention to build on it further
                when they have collated further copywriting.
              </p>
              <a href="https://ocyan.co.uk/" className="ocyanWeb__summary-link">
                <p>ocyan.co.uk</p>
              </a>
            </div>

            <div className="ocyanWeb__summary-results">
              <p className="ocyanWeb__summary-heading">RESULTS:</p>
              <p className="ocyanWeb__summary-text">
                . A concise yet informative single page scrolling brochure site
                with a contact form <br></br>
                <br></br>. A unique design following brand visual guidelines
                conveying their futuristic and playful but professional brand
                image
              </p>
              <div className="ocyanWeb__summary-techstack">
                <img src={icon1} className="ocyanWeb__summary-icon" alt=""></img>
                <img src={icon2} className="ocyanWeb__summary-icon" alt=""></img>
                <img src={icon3} className="ocyanWeb__summary-icon" alt=""></img>
                <img src={icon4} className="ocyanWeb__summary-icon" alt=""></img>
              </div>
            </div>
          </div>
        </section>

        <section className="ocyanWeb__photos">
          <div className="ocyanWeb__photos-row">
            <img src={photo1} className="ocyanWeb__photos-photo" alt=""></img>
            <img src={photo2} className="ocyanWeb__photos-photo" alt=""></img>
          </div>

          <div className="ocyanWeb__photos-row">
            <img src={photo3} className="ocyanWeb__photos-photo" alt=""></img>
            <img src={photo4} className="ocyanWeb__photos-photo" alt=""></img>
          </div>
        </section>
      </div>
      <Footer />
    </section>
  );
};

export default OcyanWeb;
