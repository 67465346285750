import "./WorkPage.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import Footer from "../../Components/Footer/Footer";

const WorkPage = () => {
  return (
    <section className="workPage">
      <div className="workPage__container">
        <div className="workPage__logo">
          <Link to="/" className="workPage__logo-link">
            <img
              className="workPage__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <h1 className="workPage__heading">WORK</h1>

        <div className="workPage__content">
          <Link
            to="/ocyan-web"
            className="workPage__card workPage__card-ocyanWeb"
          >
            <h2>OCYAN</h2>
            <p>Web Design & React Web Development</p>
          </Link>
          <Link
            to="/pier-papaiz"
            className="workPage__card workPage__card-pier"
          >
            <h2>PIERLUIGI PAPAIZ</h2>
            <p>Portfolio Design & Custom Squarespace Development</p>
          </Link>
          <Link
            to="/ocyan-branding"
            className="workPage__card workPage__card-ocyanBrand"
          >
            <h2>OCYAN</h2>
            <p>Brand Strategy & Design</p>
          </Link>
          <Link
            to="/addstyle-ocyan"
            className="workPage__card workPage__card-add"
          >
            <h2>ADD STYLE</h2>
            <p>UX Prototyping</p>
          </Link>
          <Link
            to="/dailyui"
            className="webDesign__card webDesign__card-dailyui"
          >
            <h2>DAILY UI</h2>
            <p>Web Design Concepts</p>
          </Link>
          <Link to="/alamode" className="workPage__card workPage__card-alamode">
            <h2>ALAMODE</h2>
            <p>Full Stack Development with React & Express</p>
          </Link>
          <Link
            to="/plitsch-digital"
            className="workPage__card workPage__card-plitsch"
          >
            <h2>THIS SITE!</h2>
            <p>Web Design & React Web Development</p>
          </Link>

          <Link
            to="/crushed-winery"
            className="workPage__card workPage__card-crushed"
          >
            <h2>Crushed Winery</h2>
            <p>Concept Brand & Packaging Design</p>
          </Link>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default WorkPage;
