import "./CrushedWinery.scss";
import logo from "../../../assets/logo.png";
import photo1 from "../../../assets/crushed/crushed-1.jpg";
import photo2 from "../../../assets/crushed/crushed-2.jpg";
import photo3 from "../../../assets/crushed/crushed-3.jpg";
import photo4 from "../../../assets/crushed/crushed-4.jpg";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import React from "react";

const CrushedWinery = () => {
  return (
    <section className="crushed">
      <div className="crushed__container">
        <div className="crushed__logo">
          <Link className="crushed__logo-link" to={"/"}>
            <img
              className="crushed__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <h1 className="crushed-heading">CRUSHED WINERY</h1>
        <h2 className="crushed-subheading">
          Concept Branding & Packaging Design
        </h2>

        {/* <section className="crushed-container">
          <div className="crushed__summary">
            <div className="crushed__summary-intro">
              <p className="crushed__summary-text">
                Ocyan is a software consultancy with a focus on cutting edge
                tech. Up until now they’d been operating solely through word of
                mouth with no branding, website or visualisation of the company.
                With their business accelerating in growth and a clear need for
                a public image, they knew it was time to level up and approached
                me to help bring Ocyan to life.<br></br>
                <br></br>
                They required strong branding that conveys their innovative
                approach with design that is playful whilst remaining
                professional.
              </p>
            </div>

            <div className="crushed__summary-results">
              <p className="crushed__summary-heading">RESULTS:</p>
              <p className="crushed__summary-text">
                .Brand strategy focused around the mission of ‘Bringing
                Tomorrow’s Technology to Life’ <br></br>
                <br></br>
                .A full brand identity kit including logo variations, colour
                palette, typography and graphic elements<br></br>
                <br></br>
                .Branding collateral and print designs including business cards,
                invoice, letterheads and stationary
              </p>
            </div>
          </div>
        </section> */}

        <section className="crushed__photos">
          <div className="crushed__photos-row">
            <img src={photo1} className="crushed__photos-photo" alt=""></img>
            <img src={photo3} className="crushed__photos-photo" alt=""></img>
          </div>

          <div className="crushed__photos-row">
            <img src={photo2} className="crushed__photos-photo" alt=""></img>
            <img src={photo4} className="crushed__photos-photo" alt=""></img>
          </div>
        </section>
      </div>
      <Footer />
    </section>
  );
};

export default CrushedWinery;
