import "./AboutHome.scss";
import avatar from "../../assets/avatar.png";
import React from "react";

const AboutHome = () => {
  return (
    <>
      <section className="aboutHome">
        <div className="aboutHome__container">
          <div className="aboutHome__heading">
            <h1 className="aboutHome__heading-main">Hello, I'm Georgia</h1>
            <h3 className="aboutHome__heading-sub">
              DIGITAL DESIGNER & CREATIVE DEVELOPER
            </h3>
          </div>

          <div className="aboutHome__content">
            <p className="aboutHome__text">
              A multidisciplinary creative specialising in web design,
              development and brand design. With my technical skillset,
              creativity and the specialist knowledge stemming from my degree in
              fashion marketing from the University of the Arts London, I bring
              a unique perspective to my work that blends creativity with
              strategic thinking. <br></br>
              <br></br> I'm passionate about helping businesses and individuals
              bring their brand to life through cohesive, effective design
              solutions that are both aesthetically pleasing and user-friendly.
              <br></br>
              <br></br>
              Whether you need a website, branding, or both, I work closely with
              my clients to understand their unique needs and goals. From there,
              I use my creativity and expertise to develop tailored solutions
              that not only look great but also deliver real results.
              {/* <br></br> <br></br> If you're looking for a creative partner to
              bring your brand to life, I'd love to hear from you. Let's create
              something amazing! */}
            </p>
            <img className="aboutHome__avatar" src={avatar} alt=""/>
          </div>

          <p className="aboutHome__skills">
            Web Design / Web Development / UX & UI / Custom Shopify &
            Squarespace / Brand Design / Brand Statagy / Digital Design / Print
            / Packaging / Ads / Social Media Design
          </p>
        </div>
      </section>

      <section className="aboutMob">
        <div className="aboutMob__container">
          <div className="aboutMob__heading">
            <h1 className="aboutMob__heading-main1">Hello,</h1>
            <h1 className="aboutMob__heading-main2">I'm Georgia</h1>
            <h3 className="aboutMob__heading-sub">
              DIGITAL DESIGNER & CREATIVE DEVELOPER
            </h3>
          </div>

          <div className="aboutMob__content">
            <img className="aboutMob__avatar" src={avatar} alt=""/>
            <p className="aboutMob__text">
              A multidisciplinary creative specialising in web design,
              development and brand design. With my technical skillset,
              creativity and the specialist knowledge stemming from my degree in
              fashion marketing from the University of the Arts London, I bring
              a unique perspective to my work that blends creativity with
              strategic thinking. <br></br>
              <br></br> I'm passionate about helping businesses and individuals
              bring their brand to life through cohesive, effective design
              solutions that are both aesthetically pleasing and user-friendly.
              <br></br>
              <br></br>
              Whether you need a website, branding, or both, I work closely with
              my clients to understand their unique needs and goals. From there,
              I use my creativity and expertise to develop tailored solutions
              that not only look great but also deliver real results.
              {/* <br></br> <br></br> If you're looking for a creative partner to
              bring your brand to life, I'd love to hear from you. Let's create
              something amazing! */}
            </p>
          </div>

          {/* <img src={divider} className="aboutMob__divider"></img> */}

          <p className="aboutMob__skills">
            Web Design / Web Development / UX & UI / Custom Shopify &
            Squarespace / Brand Design / Brand Statagy / Digital Design / Print
            / Packaging / Ads / Social Media Design
          </p>
        </div>
      </section>
    </>
  );
};

export default AboutHome;
