import "./Branding.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import Footer from "../../../Components/Footer/Footer";

const Branding = () => {
  return (
    <section className="branding">
      <div className="branding__container">
        <div className="branding__logo">
          <Link to="/" className="landing__logo-link">
            <img
              className="landing__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <div className="branding__header">
          <h1 className="branding__heading">BRANDING</h1>

          <div className="branding__header-buttons">
            <Link className="branding__header-button" to="/web-design">
              WEB DESIGN
            </Link>
            <Link className="branding__header-button" to="/web-development">
              WEB DEVELOPMENT
            </Link>
          </div>
        </div>
        <div className="branding__content">
          <Link
            to="/ocyan-branding"
            className="branding__card branding__card-ocyan"
          >
            <h2>OCYAN</h2>
            <p>Brand Strategy & Design</p>
          </Link>
          <Link
            to="/crushed-winery"
            className="branding__card branding__card-crushed"
          >
            <h2>Crushed Winery</h2>
            <p>Concept Brand & Packaging Design</p>
          </Link>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Branding;
