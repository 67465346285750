import "./Banner.scss";

const Banner = () => {
  return (
    <div className="marquee">
      <div className="track">
        <div className="content">
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
          DESIGN - DEVELOPMENT - BRANDING - DESIGN - DEVELOPMENT - BRANDING -
        </div>
      </div>
    </div>
  );
};

export default Banner;
