import "./NavDesktop.scss";
import { Link } from "react-router-dom";

const NavDesktop = () => {
  return (
    <header className="navDesktop">
      <nav className="navDesktop__nav">
        <Link to="/about">
          <p className="navDesktop__nav-link">ABOUT</p>
        </Link>
        <Link to="/work">
          {" "}
          <p className="navDesktop__nav-link">WORK</p>
        </Link>
        <Link to="/contact">
          <p className="navDesktop__nav-link">CONTACT</p>
        </Link>
      </nav>
      <div className="navDesktop__divider"></div>
    </header>
  );
};

export default NavDesktop;
