import "./AddStyleOcyan.scss";
import logo from "../../../assets/logo.png";
import photo1 from "../../../assets/addStyleOcyan/1.jpg";
import photo2 from "../../../assets/addStyleOcyan/2.jpg";
import photo3 from "../../../assets/addStyleOcyan/3.jpg";
import { Link } from "react-router-dom";
import Footer from "../../../Components/Footer/Footer";
import React from "react";

const AddStyleOcyan = () => {
  return (
    <div className="addStyleOcyan">
      <div className="addStyleOcyan__container">
        <div className="addStyleOcyan__logo">
          <Link className="addStyleOcyan__logo-link" to={"/"}>
            <img
              className="addStyleOcyan__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <h1 className="addStyleOcyan-heading">ADDStyle x OCYAN</h1>
        <h2 className="addStyleOcyan-subheading">UX Prototyping</h2>

        <section className="addStyleOcyan-container">
          <div className="addStyleOcyan__summary">
            <div className="addStyleOcyan__summary-intro">
              <p className="addStyleOcyan__summary-text">
                Design examples created for a client pitch to ADD Style who are
                hoping to add a customise product feature to their online store
                in the future. I had the task of creating a user friendly and
                aesthetically pleasing design that stood out whilst still being
                in line with their current branding and website design.
              </p>
            </div>

            <div className="addStyleOcyan__summary-results">
              <p className="addStyleOcyan__summary-heading">RESULTS:</p>
              <p className="addStyleOcyan__summary-text">
                The prospective clients loved the designs. As a result, although
                they are not ready to introduce the feature at present, we
                secured them as a client for rebranding along with future web
                design and development. Stay tuned!
              </p>
            </div>
          </div>
        </section>

        <section className="addStyleOcyan__photos">
          <img src={photo1} className="addStyleOcyan__photos-photo" alt=""></img>

          <img src={photo2} className="addStyleOcyan__photos-photo" alt=""></img>

          <img src={photo3} className="addStyleOcyan__photos-photo" alt=""></img>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AddStyleOcyan;
