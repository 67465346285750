import "./App.scss";

import NavDesktop from "./Components/NavDesktop/NavDesktop";
import Home from "./pages/Home/Home";

import About from "./pages/About/About";
import ContactPage from "./pages/ContactPage/ContactPage";
import WorkPage from "./pages/WorkPage/WorkPage";

import WebDesign from "./pages/Work/WebDesign/WebDesign";
import WebDevelopment from "./pages/Work/WebDevelopment/WebDevelopment";
import Branding from "./pages/Work/Branding/Branding";

import OcyanBranding from "./pages/CaseStudies/OcyanBranding/OcyanBranding";
import OcyanWeb from "./pages/CaseStudies/OcyanWeb/OcyanWeb";
import Pier from "./pages/CaseStudies/Pier/Pier";
import CrushedWinery from "./pages/CaseStudies/CrushedWinery/CrushedWinery";
import AddStyleOcyan from "./pages/CaseStudies/AddStyleOcyan/AddStyleOcyan";
import ALaMode from "./pages/CaseStudies/ALaMode/ALaMode";
import DailyUI from "./pages/CaseStudies/DailyUI/DailyUi";
import Plitsch from "./pages/CaseStudies/Plitsch/Plitsch";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <div className="App">
        <NavDesktop></NavDesktop>
        <main>
          <Routes>
            <Route exact path="/" element={<Home />} />

            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/work" element={<WorkPage />} />

            <Route path="/web-design" element={<WebDesign />} />
            <Route path="/web-development" element={<WebDevelopment />} />
            <Route path="/branding" element={<Branding />} />

            <Route path="/ocyan-branding" element={<OcyanBranding />} />
            <Route path="/ocyan-web" element={<OcyanWeb />} />
            <Route path="/pier-papaiz" element={<Pier />} />
            <Route path="/crushed-winery" element={<CrushedWinery />} />
            <Route path="/addstyle-ocyan" element={<AddStyleOcyan />} />
            <Route path="/alamode" element={<ALaMode />} />
            <Route path="/plitsch-digital" element={<Plitsch />} />

            <Route path="/dailyui" element={<DailyUI />} />
          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
