import "./ALaMode.scss";
import logo from "../../../assets/logo.png";
import icon1 from "../../../assets/react-logo.png";
import icon2 from "../../../assets/js-logo.png";
import icon3 from "../../../assets/html-logo.png";
import icon4 from "../../../assets/css-logo.png";
import icon5 from "../../../assets/node-logo.png";
import photo1 from "../../../assets/aLaMode/alamode-1.jpg";
import photo2 from "../../../assets/aLaMode/alamode-2.jpeg";
import Footer from "../../../Components/Footer/Footer";

import { Link } from "react-router-dom";
import React from "react";

const ALaMode = () => {
  return (
    <section className="aLaMode">
      <div className="aLaMode__container">
        <div className="aLaMode__logo">
          <Link className="aLaMode__logo-link" to={"/"}>
            <img
              className="aLaMode__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <h1 className="aLaMode-heading">Á LA MODE</h1>
        <h2 className="aLaMode-subheading">Fullstack Web Development</h2>

        <section className="aLaMode-container">
          <div className="aLaMode__summary">
            <div className="aLaMode__summary-intro">
              <p className="aLaMode__summary-text">
                Fashion is a huge contributor to the current climate crisis -
                causing 10% of human caused greenhouse gas emissions, 10% of
                global waste water, 53 million tonnes of clothing sent to
                landfill every year 48% of UK adults have not adopted more
                sustainable habits due to lack of information. We must change
                our attitudes and consumption habits fast, but how can we make
                change without knowing where to start?<br></br>
                <br></br> A mobile web app created as a one stop resource for
                all things Fashion sutainability. Development took place in a 2
                week timeframe as a capstone project upon finishing my diploma
                in Web Development.
              </p>
              <a
                href="https://github.com/GeorgiaPlitsch/alamode"
                className="aLaMode__summary-link"
              >
                <p>Github Repo</p>
              </a>
            </div>

            <div className="aLaMode__summary-results">
              <p className="aLaMode__summary-heading">FEATURES:</p>
              <p className="aLaMode__summary-text">
                . Fashion Sutainibility Quiz - find out the sustainability
                rating score of your current fashion consumption habits and
                receive a personalised reccomended next step. <br></br>
                <br></br>. Clothing Composition Checker - A tool to help make
                informed decisions when purchasing. Input the fabric composition
                of a clothing item to receive clear advice and info on the
                sustainability levels of the materials used
                <br></br>
                <br></br>. Client techstack: React, JS, HTML, CSS/SASS, AXIOS,
                npm
                <br></br>
                <br></br>. Server techstack: Node, Express
              </p>
              <div className="aLaMode__summary-techstack">
                <img src={icon5} className="aLaMode__summary-icon" alt=""></img>
                <img src={icon1} className="aLaMode__summary-icon" alt=""></img>
                <img src={icon2} className="aLaMode__summary-icon" alt=""></img>
                <img src={icon3} className="aLaMode__summary-icon" alt=""></img>
                <img src={icon4} className="aLaMode__summary-icon" alt=""></img>
              </div>
            </div>
          </div>
        </section>

        <section className="aLaMode__photos">
          <div className="aLaMode__photos-row">
            <img src={photo1} className="aLaMode__photos-photo" alt=""></img>
            <img src={photo2} className="aLaMode__photos-photo" alt=""></img>
          </div>
        </section>
      </div>
      <Footer />
    </section>
  );
};

export default ALaMode;
