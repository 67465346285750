import "./Landing.scss";
import logo from "../../assets/logo.png";
import Banner from "../Banner/Banner";
import instagram from "../../assets/instagram.png";
// import youtube from "../../assets/youtube.png";
import behance from "../../assets/behance.png";
import linkedin from "../../assets/linkedin.png";
import sparkle1 from "../../assets/hero-sparkle-1.png";
import sparkle2 from "../../assets/hero-sparkle-2.png";
import { Link } from "react-router-dom";
import React from "react";

const Landing = () => {
  return (
    <section className="section landing">
      <div className="landing__logo">
        <Link to="/" className="landing__logo-link">
          <img
            className="landing__logo-img"
            src={logo}
            alt="plitsch digital"
          ></img>
        </Link>
      </div>

      <Banner />

      <div className="landing__banner-placeholder"></div>

      <div className="landing__hero">
        <div className="landing__socials">
          <a href="https://www.instagram.com/plitschdigital/">
            <img src={instagram} className="landing__socials-icon" alt=""></img>
          </a>
          {/* <a href="https://www.youtube.com/channel/UCp4FuCWFW_6jTjwznwuBBUQ">
            <img src={youtube} className="landing__socials-icon" alt=""></img>
          </a> */}
          <a href="https://www.linkedin.com/in/georgia-plitsch/">
            <img src={linkedin} className="landing__socials-icon" alt=""></img>
          </a>
          <a href="https://www.behance.net/georgiaplitsch1">
            <img src={behance} className="landing__socials-icon" alt=""></img>
          </a>
        </div>
        <div className="landing__hero-container">
          <img src={sparkle1} className="landing__hero-sparkle1" alt=""></img>
          <h1 className="landing__hero-text">
            MEANINGFUL<br></br>DIGITAL<br></br>EXPERIENCES
          </h1>
          <img src={sparkle2} className="landing__hero-sparkle2" alt=""></img>
        </div>
      </div>
    </section>
  );
};

export default Landing;
