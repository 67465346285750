import "./WebDesign.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import Footer from "../../../Components/Footer/Footer";

const WebDesign = () => {
  return (
    <section className="webDesign">
      <div className="webDesign__container">
        <div className="webDesign__logo">
          <Link to="/" className="landing__logo-link">
            <img
              className="landing__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <div className="webDesign__header">
          <h1 className="webDesign__heading">WEB DESIGN</h1>

          <div className="webDesign__header-buttons">
            <Link className="webDesign__header-button" to="/branding">
              BRANDING
            </Link>
            <Link className="webDesign__header-button" to="/web-development">
              WEB DEVELOPMENT
            </Link>
          </div>
        </div>

        <div className="webDesign__content">
          <Link
            to="/ocyan-web"
            className="webDesign__card webDesign__card-ocyan"
          >
            <h2>OCYAN</h2>
            <p>Web Design & React Web Development</p>
          </Link>
          <Link
            to="/pier-papaiz"
            className="webDesign__card webDesign__card-pier"
          >
            <h2>PIERLUIGI PAPAIZ</h2>
            <p>Portfolio Design & Custom Squarespace Development</p>
          </Link>
          <Link
            to="/addstyle-ocyan"
            className="webDesign__card webDesign__card-add"
          >
            <h2>ADD STYLE</h2>
            <p>UX Prototyping</p>
          </Link>
          <Link
            to="/dailyui"
            className="webDesign__card webDesign__card-dailyui"
          >
            <h2>DAILY UI</h2>
            <p>Web Design Concepts</p>
          </Link>
          <Link
            to="/plitsch-digital"
            className="webDesign__card webDesign__card-plitsch"
          >
            <h2>THIS SITE!</h2>
            <p>Web Design & React Web Development</p>
          </Link>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default WebDesign;
