import { Link } from "react-router-dom";

import Hamburger from "./Hamburger/Hamburger.js";
import { useState } from "react";

export default function NavMobile() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  return (
    <div id="myMenu" className="menu__wrap">
      <div id="menu" className="icon" onClick={toggleHamburger}>
        <Hamburger isOpen={hamburgerOpen} />
      </div>
      <div id="nav" className="navigation">
        <nav className="navigation__wrapper">
          <div className="navigation__container">
            <Link onClick={toggleHamburger} to="/">
              <h2 className="navigation__link-text">HOME</h2>
            </Link>
            <Link onClick={toggleHamburger} to="/about">
              <h2 className="navigation__link-text">ABOUT</h2>
            </Link>
            <Link onClick={toggleHamburger} to="/work">
              <h2 className="navigation__link-text">WORK</h2>
            </Link>
            <Link onClick={toggleHamburger} to="/contact">
              <h2 className="navigation__link-text">CONTACT</h2>
            </Link>
          </div>
        </nav>
      </div>

      <style jsx>{`
        .menu__wrap {
          z-index: 1000;
          position: fixed;
          z-index: 10000;

        }

        .icon {
          pointer-events: all;
          background-colour: red;
          top: 50px;
          left: 85vw;
          z-index: 2000;
        }

        .navigation nav{
          display: ${hamburgerOpen ? "flex" : "none"};
         
        
          flex-direction: column;
          justify-content: center;
          background-color: #FFAE59;
          border-left: solid 2px #F48636;
          height: 100vh;
          width: 70vw;
          position: fixed;
          top: 0;
          right: 0;
          z-index:1000;
          margin-top:0;
          padding: 24;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -webkit-backdrop-filter: blur(10px);
          
      }

      .navigation__container{
        padding: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8vh;
        height: 80%;
      }

      .navigation__link-text{
        font-family: arthead;
        color: #FEFEE3;
        width: fit-content;
       padding: 12px;
      }


        }
      `}</style>

      {/* .icon{
          position: fixed;
          top: 50px;
          right: 50px;
          z-index: 1000;
        }
       */}
    </div>
  );
}
