import "./Contact.scss";
import ContactForm from "./ContactForm/ContactForm";

const Contact = () => (
  <section className="contact">
    <div className="contact__container">
      <h1 className="contact__heading">CONTACT</h1>
      <div className="contact__content">
        <div className="contact__left">
          <p className="contact__text">
            Have any questions or a project you'd like to discuss?
          </p>
          <p className="contact__text">Let's chat!</p>
          <p className="contact__text">
            Please complete the contact form with any queuries and I will be
            back in touch with you shortly!
          </p>
          <p className="contact__text contact__text-email">
            Alternatively, e-mail me directly at:
          </p>
          <a
            className="contact__text contact__text-link"
            href="mailto:georgia.plitsch@outlook.com"
          >
            georgia.plitsch@outlook.com
          </a>
        </div>
        <div className="contact__right">
          <ContactForm />
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
