import "./Pier.scss";
import logo from "../../../assets/logo.png";
import icon1 from "../../../assets/squarespace-logo.png";
import icon2 from "../../../assets/js-logo.png";
import icon3 from "../../../assets/html-logo.png";
import icon4 from "../../../assets/css-logo.png";
import photo1 from "../../../assets/pier/pier-1.jpg";
import photo2 from "../../../assets/pier/pier-2.jpg";
import photo3 from "../../../assets/pier/pier-3.webp";
import photo4 from "../../../assets/pier/pier-4.webp";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";

const Pier = () => {
  return (
    <section className="pier">
      <div className="pier__container">
        <div className="pier__logo">
          <Link className="pier__logo-link" to={"/"}>
            <img
              className="pier__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <h1 className="pier-heading">PIERLUIGI PAPAIZ</h1>
        <h2 className="pier-subheading">
          Portfolio Design & Custom Squarespace Development
        </h2>

        <section className="pier-container">
          <div className="pier__summary">
            <div className="pier__summary-intro">
              <p className="pier__summary-text">
                Pier is a London based sound designer & recordist - he wanted to
                establish himself with a professional website to attract clients
                and showcase his work, his services and a bit of what he’s
                about.
                <br></br>
                <br></br>
                He had dabbled in making his own squarespace site, however was
                frustrated with the limitations of the standard squarespace
                offering and wanted a site that stood out with unique features.
              </p>
              <a
                href="https://synthesizer-springtail-7tmr.squarespace.com/"
                className="pier__summary-link"
              >
                <p>coming soon</p>
              </a>
            </div>

            <div className="pier__summary-results">
              <p className="pier__summary-heading">RESULTS:</p>
              <p className="pier__summary-text">
                . A unique, user friendly portfolio site built on squarespace
                fluid engine <br></br>
                <br></br>. Fully customised with code to leave the confines of
                standard squarespace sites; enabling custom layouts, design
                features and functionality.
              </p>
              <div className="pier__summary-techstack">
                <img src={icon1} className="pier__summary-icon" alt=""></img>
                <img src={icon2} className="pier__summary-icon" alt=""></img>
                <img src={icon3} className="pier__summary-icon" alt=""></img>
                <img src={icon4} className="pier__summary-icon" alt=""></img>
              </div>
            </div>
          </div>
        </section>

        <section className="pier__photos">
          <div className="pier__photos-row">
            <img src={photo1} className="pier__photos-photo" alt=""></img>
            <img src={photo2} className="pier__photos-photo" alt=""></img>
          </div>

          <div className="pier__photos-row">
            <img src={photo3} className="pier__photos-photo" alt=""></img>
            <img src={photo4} className="pier__photos-photo" alt=""></img>
          </div>
        </section>
      </div>
      <Footer />
    </section>
  );
};

export default Pier;
