import "./Footer.scss";
import instagram from "../../assets/instagram.png";
import behance from "../../assets/behance.png";
// import youtube from "../../assets/youtube.png";
import linkedin from "../../assets/linkedin.png";
import { Link } from "react-router-dom";
import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__icons">
        <a
          className="footer__icons-link"
          href="https://www.instagram.com/plitschdigital/"
        >
          <img src={instagram} className="footer__icons-img" alt=""></img>
        </a>
        <a
          className="footer__icons-link"
          href="https://www.behance.net/georgiaplitsch1"
        >
          <img src={behance} className="footer__icons-img" alt=""></img>
        </a>
        <a
          className="footer__icons-link"
          href="https://www.linkedin.com/in/georgia-plitsch/"
        >
          <img src={linkedin} className="footer__icons-img" alt=""></img>
        </a>
        {/* <a className="footer__icons-link" href="https://www.youtube.com/">
          <img src={youtube} className="footer__icons-img" alt=""></img>
        </a> */}
      </div>

      <div className="footer__links">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/work">Work</Link>
        <Link to="/contact">Contact</Link>
        <p>Copyright © 2023 Georgia Plitsch. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
