import "./About.scss";
import logo from "../../assets/logo.png";
import photo from "../../assets/about-photo.jpg";
import sparkle1 from "../../assets/services-sparkle1.png";
import sparkle2 from "../../assets/services-sparkle2.png";

import Footer from "../../Components/Footer/Footer";

import { Link } from "react-router-dom";
import React from "react";

const About = () => {
  return (
    <div className="about">
      <div className="about__inner">
        <div className="about__logo">
          <Link className="about__logo-link" to={"/"}>
            <img
              className="about__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>

        <h1 className="about__heading">ABOUT</h1>
        <div className="about__bio">
          <p className="about__text">
            I'm a tech focused creative based in London, specialising in web
            design, web development, and brand design. My background in fashion
            marketing at the University of the Arts London has given me a unique
            perspective on the intersection of aesthetics, functionality, and
            user experience. <br></br>
            <br></br>After my studies, I pursued my passion for user-centred
            design and learned the art of creating intuitive interfaces and
            engaging experiences through UX design. I then developed my
            technical skills and became a full-stack web developer, proficient
            in both front-end and back-end development.
            <br></br>
            <br></br>
            With my diverse skillset, I bring a holistic approach to brand
            design, crafting cohesive visual identities that are not only
            beautiful but also effective in achieving business goals. From the
            first consultation to the final deliverable, I work closely with my
            clients to understand their unique needs and bring their vision to
            life.
            <br></br>
            <br></br>I'm passionate about creating websites and brands that
            stand out in today's digital landscape. Whether you're a startup
            looking to establish your online presence, an established business
            seeking a refresh or an individual looking for a striking portfolio
            - I'm here to help.
          </p>
          <div className="about__photo">
            <img className="about__photo-img" src={photo} alt=""></img>
          </div>
        </div>

        <h2 className="about__subheading">What I do...</h2>

        <div className="about__container">
          <div className="about__service about__service--left">
            <div className="about__title">
              <img className="about__title-sparkle" src={sparkle1} alt=""></img>
              <h3 className="about__title-text">WEB DESIGN</h3>
            </div>
            <p className="about__service-text">
              Stunning websites that make a lasting impression. From refreshes
              to designing sites from scratch, I design user friendly, visually
              appealing sites that accurately represent your brand and will
              capture your audiences attention.
            </p>
          </div>

          <div className="about__service about__service--right">
            <div className="about__title">
              <img className="about__title-sparkle" src={sparkle2} alt=""></img>
              <h3 className="about__title-text">WEBSITE BUILDS</h3>
            </div>
            <p className="about__service-text">
              I build custom sites for a variety of budgets and needs, whether
              you need a simple brochure site or a complex e-commerce platform,
              either from scratch with code or through popular platforms such as
              shopify and squarespace.
            </p>
          </div>

          <div className="about__service about__service--left">
            <div className="about__title">
              <img className="about__title-sparkle" src={sparkle1} alt=""></img>
              <h3 className="about__title-text">BRANDING</h3>
            </div>
            <p className="about__service-text">
              Defining brand personality, values, and messaging, and creating a
              comprehensive branding strategy that resonates with your target
              audience. From logo design and brand guidelines to packaging
              design. l can help you stand out from the competition.
            </p>
          </div>

          <div className="about__service about__service--right">
            <div className="about__title">
              <img className="about__title-sparkle" src={sparkle2} alt=""></img>
              <h3 className="about__title-text">ASSET DESIGN</h3>
            </div>
            <p className="about__service-text">
              I design custom graphics, icons, and other visual elements that
              align with your brand identity and support your marketing efforts.
              Whether you need social media templates, product packaging,
              marketing collateral, or a complete visual rebrand, let me help
              you elevate your brand.
            </p>
          </div>

          <div className="about__service about__service--left">
            <div className="about__title">
              <img className="about__title-sparkle" src={sparkle1} alt=""></img>
              <h3 className="about__title-text">SQUARESPACE</h3>
            </div>
            <p className="about__service-text">
              I offer a range of Squarespace services, including fully custom
              website designs and builds with custom coded features,
              pre-designed and built templates, fixes, refreshes, and
              consulting. Whether you're starting from scratch or looking to
              improve an existing site, I can help you create a beautiful and
              functional Squarespace website that meets your needs.
            </p>
          </div>

          <Link to="/contact" className="about__button">
            GET IN TOUCH
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
