import "./Home.scss";
import Landing from "../../Components/Landing/Landing";
import Work from "../../Components/Work/Work";
import AboutHome from "../../Components/AboutHome/AboutHome";
import arrowMob from "../../assets/arrow-mob.png";
import Contact from "../../Components/Contact/Contact";
import Footer from "../../Components/Footer/Footer";
import React from "react";

const Home = () => {
  const handleClickScroll = () => {
    const element = document.getElementById("slide2");
    element?.scrollIntoView({ behavior: "smooth", inline: "start" });
  };

  return (
    <div className="home scroll">
      <img src={arrowMob} className="home__arrow" onClick={handleClickScroll} alt=""/>
      <img
        src={arrowMob}
        className="home__arrowMob"
        onClick={handleClickScroll}
        alt=""
      />

      <div className="slide">
        <Landing />
      </div>
      <div className="slide" id="slide2">
        <AboutHome />
      </div>
      <div className="slide">
        <Work />
      </div>
      <div className="slide">
        <Contact />
      </div>
      <div className="slide slide-footer">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
