import "./Plitsch.scss";
import logo from "../../../assets/logo.png";
import icon1 from "../../../assets/react-logo.png";
import icon2 from "../../../assets/js-logo.png";
import icon3 from "../../../assets/html-logo.png";
import icon4 from "../../../assets/css-logo.png";
import icon5 from "../../../assets/aws-logo.png";
import photo1 from "../../../assets/plitsch/plitsch-1.jpg";
import photo2 from "../../../assets/plitsch/plitsch-2.jpg";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import React from "react";

const Plitsch = () => {
  return (
    <section className="plitsch">
      <div className="plitsch__container">
        <div className="plitsch__logo">
          <Link className="plitsch__logo-link" to={"/"}>
            <img
              className="plitsch__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <h1 className="plitsch-heading">PLITSCH DIGITAL</h1>
        <h2 className="plitsch-subheading">Web Design & Development</h2>

        <section className="plitsch-container">
          <div className="plitsch__summary">
            <div className="plitsch__summary-intro">
              <p className="plitsch__summary-text">
                This site is a showcase of my web development and design
                projects, featuring a modern and sleek design with a
                user-friendly interface. As a web developer and designer, it was
                important for me to create a portfolio site that not only
                demonstrated my technical skills but also my design abilities.
                <br></br>
                <br></br>My goal when designing was to convey a strong personal
                brand and consistent theme throughout the site and to
                incorporate unique and aesthetically pleasing design features
                whilst ensuring the site remains simple and user friendly.
              </p>
              <a
                href="https://plitsch.digital/"
                className="plitsch__summary-link"
              >
                <p>home</p>
              </a>
            </div>

            <div className="plitsch__summary-results">
              <p className="plitsch__summary-heading">RESULTS:</p>
              <p className="plitsch__summary-text">
                . Portfolio site developed with React, allowing for easy
                management and organisation of the site's content. <br></br>
                <br></br>. A unique design with a consistent theme and strong
                personal branding
              </p>
              <div className="plitsch__summary-techstack">
                <img src={icon1} className="plitsch__summary-icon" alt=""></img>
                <img src={icon2} className="plitsch__summary-icon" alt=""></img>
                <img src={icon3} className="plitsch__summary-icon" alt=""></img>
                <img src={icon4} className="plitsch__summary-icon" alt=""></img>
                <img src={icon5} className="plitsch__summary-icon" alt=""></img>
              </div>
            </div>
          </div>
        </section>

        <section className="plitsch__photos">
          <div className="plitsch__photos-row">
            <img src={photo1} className="plitsch__photos-photo" alt=""></img>
            <img src={photo2} className="plitsch__photos-photo"alt=""></img>
          </div>
        </section>
      </div>
      <Footer />
    </section>
  );
};

export default Plitsch;
