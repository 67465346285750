import "./DailyUi.scss";
import logo from "../../../assets/logo.png";
import photo1 from "../../../assets/DailyUi/1.jpg";
import photo2 from "../../../assets/DailyUi/2.jpg";
import photo3 from "../../../assets/DailyUi/3.jpg";
import photo4 from "../../../assets/DailyUi/4.jpg";
import photo5 from "../../../assets/DailyUi/5.jpg";
// import photo6 from "../../../assets/DailyUi/6.jpg";
import photo7 from "../../../assets/DailyUi/7.jpg";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import React from "react";

const DailyUI = () => {
  return (
    <div className="dailyUi">
      <div className="dailyUi__container">
        <div className="dailyUi__logo">
          <Link className="dailyUi__logo-link" to={"/"}>
            <img
              className="dailyUi__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>
        <h1 className="dailyUi-heading">Daily UI</h1>
        <h2 className="dailyUi-subheading">Rapid UI Design</h2>

        <section className="dailyUi__photos">
          <div className="dailyUi__photos-left">
            <img src={photo1} className="dailyUi__photos-photo" alt=""></img>
            <img src={photo5} className="dailyUi__photos-photo" alt=""></img>
          </div>

          <div className="dailyUi__photos-right">
            <img src={photo2} className="dailyUi__photos-photo" alt=""></img>
            <img src={photo3} className="dailyUi__photos-photo" alt=""></img>
            <img src={photo4} className="dailyUi__photos-photo" alt=""></img>
            <img src={photo7} className="dailyUi__photos-photo" alt=""></img>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default DailyUI;
