import "./Work.scss";
import { Link } from "react-router-dom";

import sparkle from "../../assets/sparkle.png";
import arrow from "../../assets/arrow-left.png";
import React from "react";

const Work = () => {
  return (
    <section className="work">
      <div className="work__container">
        <h1 className="work__heading">WORK</h1>
        <div className="work__categories">
          <Link to="/web-design" className="work__category">
            <img src={sparkle} className="work__category-sparkle" alt=""></img>
            <h2 className="work__category-text">WEB DESIGN</h2>
            <img src={arrow} className="work__category-arrow" alt=""></img>
          </Link>

          <Link
            to="/web-development"
            className="work__category work__category--right"
          >
            <img src={sparkle} className="work__category-sparkle" alt=""></img>
            <h2 className="work__category-text">DEVELOPMENT</h2>
            <img src={arrow} className="work__category-arrow" alt=""></img>
          </Link>

          <Link to="/branding" className="work__category">
            <img src={sparkle} className="work__category-sparkle" alt=""></img>
            <h2 className="work__category-text">BRANDING</h2>
            <img src={arrow} className="work__category-arrow" alt=""></img>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Work;
