import "./ContactPage.scss";
import logo from "../../assets/logo.png";
import ContactForm from "../../Components/Contact/ContactForm/ContactForm";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";

const ContactPage = () => (
  <div className="contactPage">
    <div className="contactPage__inner">
      <div className="contactPage__logo">
        <Link className="contactPage__logo-link" to={"/"}>
          <img
            className="contactPage__logo-img"
            src={logo}
            alt="plitsch digital"
          ></img>
        </Link>
      </div>

      <h1 className="contactPage__heading">CONTACT</h1>
      <div className="contact__content">
        <div className="contact__left">
          <p className="contact__text">
            Have any questions or a project you'd like to discuss?
          </p>
          <p className="contact__text">Let's chat!</p>
          <p className="contact__text">
            Please complete the contact form with any queuries and I will be
            back in touch with you shortly!
          </p>
          <p className="contact__text contact__text-email">
            Alternatively, e-mail me directly at:
          </p>
          <a
            className="contact__text contact__text-link"
            href="mailto:georgia.plitsch@outlook.com"
          >
            georgia.plitsch@outlook.com
          </a>
        </div>
        <div className="contact__right">
          <ContactForm />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default ContactPage;
