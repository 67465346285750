import "./WebDevelopment.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import Footer from "../../../Components/Footer/Footer";

const WebDevelopment = () => {
  return (
    <section className="webDevelopment">
      <div className="webDevelopment__container">
        <div className="webDevelopment__logo">
          <Link to="/" className="landing__logo-link">
            <img
              className="landing__logo-img"
              src={logo}
              alt="plitsch digital"
            ></img>
          </Link>
        </div>

        <div className="webDevelopment__header">
          <h1 className="webDevelopment__heading">WEB DEVELOPMENT</h1>

          <div className="webDevelopment__header-buttons">
            <Link className="webDevelopment__header-button" to="/branding">
              BRANDING
            </Link>
            <Link className="webDevelopment__header-button" to="/web-design">
              WEB DESIGN
            </Link>
          </div>
        </div>

        <div className="webDevelopment__content">
          <Link
            to="/ocyan-web"
            className="webDevelopment__card webDevelopment__card-ocyan"
          >
            <h2>OCYAN</h2>
            <p>Web Design & React Web Development</p>
          </Link>
          <Link
            to="/pier-papaiz"
            className="webDevelopment__card webDevelopment__card-pier"
          >
            <h2>PIERLUIGI PAPAIZ</h2>
            <p>Portfolio Design & Custom Squarespace Development</p>
          </Link>
          <Link
            to="/plitsch-digital"
            className="webDevelopment__card webDevelopment__card-plitsch"
          >
            <h2>THIS SITE!</h2>
            <p>Web Design & React Web Development</p>
          </Link>
          <Link
            to="/alamode"
            className="webDevelopment__card webDevelopment__card-alamode"
          >
            <h2>ALAMODE</h2>
            <p>Full Stack Development with React & Express</p>
          </Link>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default WebDevelopment;
